<template>
  <div
    style="margin: 0 auto; overflow-x: auto; "
    class="py-1"
    data-tag="programmingInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <v-layout class="justify-end">
      <v-switch
        large
        class="mt-0"
        v-model="darkMode"
        inset
        dense
        hide-details
        :label="$t('dark_mode')"
      ></v-switch>
    </v-layout>
    <div ref="editorEl" class="programmingEditor"></div>
  </div>
</template>

<script>
import {
  watch,
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  defineComponent,
  useAttrs,
  getCurrentInstance
} from "vue";
import { debounce } from "@/helpers";
import store from "@/store/index";

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";
import { useCodemirror } from "../composables/useCodemirror";

export default defineComponent({
  name: "programmingInteraction",

  mixins: [responseIdentifierMixin],

  props: {
    dataLang: { type: String, required: true }
  },

  setup(props) {
    const editorEl = ref(null);
    const attrs = useAttrs();
    const instance = getCurrentInstance();

    const required = attrs["data-required"] === "true";
    const autocompletion = attrs["data-autocompletion"] === "true";

    let editor = null;
    let firstSave = true;

    const storedValue = computed(() =>
      store.getters.responseValue(props.responseIdentifier)
    );
    const revision = computed(() => store.getters.revision);

    let text = "";
    if (storedValue.value) {
      const div = document.createElement("div");
      div.innerHTML = storedValue.value.html;
      text = div.innerText;
      div.remove();
    }
    const {
      createEditor,
      value,
      getSubmissionData,
      darkMode,
      setEditorContents
    } = useCodemirror(props.dataLang, autocompletion, text, () =>
      saveData(true)
    );

    function saveData(contentCheck) {
      debouncedCommit.clear();
      const s = getSubmissionData();
      if (
        (contentCheck || firstSave) &&
        s.html == (storedValue.value ? storedValue.value.html : "")
      ) {
        firstSave = false;
        return;
      }
      instance.proxy.commitValue(s);
    }

    const debouncedCommit = debounce(saveData, 2000, 4000);

    watch(value, () => {
      debouncedCommit.call();
    });
    watch(revision, revision => {
      if (!revision || !revision.form) return;
      let text = "";
      if (revision.form[props.responseIdentifier]) {
        const div = document.createElement("div");
        div.innerHTML = revision.form[props.responseIdentifier].html;
        text = div.innerText;
        div.remove();
      }
      setEditorContents(text);
    });

    store.commit("setCustomValidator", {
      responseIdentifier: props.responseIdentifier,
      fn: () => {
        return storedValue.value && storedValue.value.html;
      }
    });

    onMounted(() => {
      // for some reason proxy is not property populated before mount
      instance.proxy.registerResponseIdentifier();
      instance.proxy.setResponseIdentifierRequired(required);

      const offset = window.innerHeight - 150;
      editorEl.value.style.setProperty("--max-height", `${offset}px`);
      window.addEventListener("resize", () => {
        const offset = window.innerHeight - 150;
        editorEl.value.style.setProperty("--max-height", `${offset}px`);
      });

      createEditor(editorEl.value).then(e => {
        editor = e;
        instance.proxy.interactionInitialized();
      });

      store.commit("addCleanupCallback", () => {
        saveData(true);
        return Promise.resolve();
      });
    });

    onBeforeUnmount(() => {
      if (editor) editor.destroy();
    });

    return {
      editorEl,
      darkMode
    };
  }
});
</script>

<style>
.programmingEditor .cm-editor {
  min-height: 300px;
  max-height: var(--max-height);
}
.programmingEditor .cm-editor .cm-scroller {
  min-height: 300px;
}
</style>
